<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar :model="menu" @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <!-- <img alt="Logo" :src="logo" /> -->
            <span id="logo">EMMER</span>
          </router-link>
        </div>

        <!-- <AppProfile /> -->
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      :layoutColorMode="layoutColorMode"
      @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange"
    /> -->

    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
//import AppProfile from "./AppProfile.vue";
import AppMenu from "./AppMenu.vue";
//import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import logo from "./assets/BucketMC.webp";
export default {
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Tickets",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Overview",
              icon: "pi pi-fw pi-list",
              to: "/Tickets",
            },
            {
              label: "New",
              icon: "pi pi-fw pi-plus",
              to: "/Tickets/New",
            },
            {
              label: "Edit",
              icon: "pi pi-fw pi-pencil",
              to: "/Tickets/Edit",
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-minus",
              to: "/Tickets/Delete",
            },
          ],
        },
        {
          label: "Accounts",
          icon: "pi pi-fw pi-user",
          items: [
            {
              icon: "pi pi-fw pi-list",
              label: "Overview",
              to: "/Accounts",
            },
            {
              label: "New",
              icon: "pi pi-fw pi-user-plus",
              to: "/Accounts/New",
            },
            {
              label: "Edit",
              icon: "pi pi-fw pi-pencil",
              to: "/Accounts/Edit",
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-user-minus",
              to: "/Accounts/Delete",
            },
            
          ],
        },
        {
          label: "HelloWorld",
          icon: "pi pi-fw pi-compass",
          to: "/HelloWorld",
        },
        {
          label: "Quit",
          icon: "pi pi-fw pi-sign-out",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,

          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? logo
        : logo;
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    //AppProfile: AppProfile,
    AppMenu: AppMenu,
    //AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
#logo{
  font-family: 'Bebas Neue', cursive;
  font-size: 100px;
}
</style>
