import { createWebHistory, createRouter } from "vue-router";


import NotFound from "../pages/NotFound.vue";
import HelloWorld from "../components/HelloWorld.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue"),
  },
  {
    path: "/HelloWorld",
    name: "HelloWorld",
    component: HelloWorld,
  },
  {
    path: "/Tickets",
    name: "Tickets",
    component: () => import("../pages/Tickets.vue"),
  },
  {
    path: "/Accounts",
    name: "Accounts",
    component: () => import("../pages/Accounts.vue"),
  },
  {
    path: "/About",
    name: "About",
    component: () => import("../pages/About.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound.vue,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
