<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin: 0 5px 0 5px">© 2021 Copyright:
      <a href="https://www.linkedin.com/in/maarten-olijve-662596122/">Emmer</a>
	</span>
    <img src="./assets/BucketMC.webp" alt="sigma" width="20" />
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped></style>
