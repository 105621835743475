<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <input name="search" />
    <Button @click="performSearch" label="Search1"/>
    <Button @click="performSearch2(searchTerm)" label="Search2"/>
    <Button @click="getAllAccounts" label="GetAllAccounts"/>
    <Button @click="getAllTickets" label="GetAllTickets"/>
    <input name="title" v-model="searchTerm" />

    <table>
      <tr>
        <th>Action</th>
        <th>Input</th>
      </tr>
      <tr>
        <td><Button @click="getTicketById(idInput)" label="Ticket by id"/></td>
        <td><input v-model="idInput" /></td>
      </tr>
      <tr>
        <!-- <td><input v-model="AccountId" /></td> -->
        <td>
          <Button @click="getAccountById(idInput)" label="Account by id"/>
        </td>
      </tr>
      <tr>
        <!-- <td><input v-model="AccountId2" /></td> -->
        <td>
          <Button @click="getTicketsByAccountId(idInput)" label="Tickets by Account id"/>
        </td>
      </tr>
      <tr>
        <!-- <td><input v-model="TicketId2" /></td> -->
        <td>
          <Button @click="getAccountsByTicketId(idInput)" label="Accounts by Ticket id"/>
        </td>
      </tr>
      <td><Button @click="getAllTickets" label="GetAllTickets"/></td>
      <tr></tr>
      <tr>
        <td>
          <Button @click="getAllAccounts" label="GetAllAccounts"/>
        </td>
      </tr>
    </table>

    <!-- <table>
      <td v-for="(object) in objects" :key="object.id">
        <ul>
          <table v-for="(item, key, index) in object" :key="key">
            {{index}} {{ item }}
            <ul>
              <li v-for="(value, attribute, index) in item" :key="index">
                {{attribute}}: {{value}}
              </li>
            </ul>
          </table>
        </ul>
      </td>
    </table> -->

    <!-- <ul>
      <li v-for="object in result" :key="object[0]">
        <table v-for="item in object" :key="item[0]">
          <tr v-for="(value, attribute, index) in item" :key="index">
            <td>
              {{ attribute }}
            </td>
            <td>
              {{ value }}
            </td>
          </tr>
        </table>
      </li>
    </ul> -->
    <h3>Results amount: {{ result.length }}</h3>
    <ul v-for="item in result" :key="item.id">
      <table v-for="(value, attribute, index) in item" :key="index">
        <tr>
          <td>{{ attribute }}:</td>
          <td>{{ value }}</td>
        </tr>
      </table>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
let link = `http://diadras.net:1234`;
//let link2 = "https://localhost:44353";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      result: [],
      arrayLength: 0,
    };
  },
  methods: {
    performSearch() {
      console.log(this.searchTerm);
    },
    performSearch2: function (searchTerm) {
      console.log(searchTerm);
    },
    getAllAccounts() {
      axios
        .get(link + "/Account/GetAll/")
        .then((response) => {
          this.result = response.data.accounts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountById(id) {
      axios
        .get(link + "/Account/" + id)
        .then((response) => {
          this.result = response.data.accounts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountsByTicketId(id) {
      axios
        .get(link + "/Account/Ticket/" + id)
        .then((response) => {
          this.result = response.data.accounts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllTickets() {
      axios
        .get(link + "/Ticket/GetAll/")
        .then((response) => {
          this.result = response.data.tickets;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTicketById(id) {
      axios
        .get(link + "/Ticket/" + id)
        .then((response) => {
          this.result = response.data.tickets;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    getTicketsByAccountId(id) {
      axios
        .get(link + "/Ticket/Account/" + id)
        .then((response) => {
          this.result = response.data.tickets;
        })
        .catch((error) => {
          console.log(error);
        });
    }    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
